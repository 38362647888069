<template>
  <div>
    <div id="editorjs"></div>
  </div>
</template>

<script>
/* eslint-disable no-unreachable */
import { v4 as uuidv4 } from "uuid";
import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
import ImageTool from "@editorjs/image";
import AttachesTool from "@editorjs/attaches";

// eslint-disable-next-line no-unused-vars
function readAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.readAsDataURL(file);
  });
}

export default {
  name: "CardEditor",
  props: {
    card: Object,
    projectId: String,
    project: Object,
  },
  prev: {},
  data: () => ({
    editor: null,
    onChange: null,
  }),
  beforeDestroy() {
    self.editor?.destroy && self.editor.destroy();
  },
  mounted() {
    this.$nextTick(this.placeEditor);
  },
  methods: {
    save() {
      const self = this;
      self.editor.save().then((savedData) => {
        self.card.content = savedData;
      });
    },
    placeEditor() {
      const self = this;
      self.editor = new EditorJS({
        data: self.card.content,
        logLevel: "ERROR",
        autofocus: true,
        /**
         * This Tool will be used as default
         */
        initialBlock: "paragraph",
        // readOnly: true,
        tools: {
          header: {
            class: Header,
            shortcut: "CMD+SHIFT+H",
          },
          list: {
            class: List,
            inlineToolbar: ["link"],
          },
          paragraph: {
            class: Paragraph,
            config: {
              placeholder: ".",
            },
          },
          attaches: {
            class: AttachesTool,
            config: {
              uploader: {
                /**
                 * Upload file to the server and return an uploaded image data
                 * @param {File} file - file selected from the device or pasted by drag-n-drop
                 * @return {Promise.<{success, file: {url}}>}
                 */
                // eslint-disable-next-line no-unused-vars
                async uploadByFile(file) {
                  const pFileName = file.name.split(".");
                  const fileExtension =
                    pFileName.length > 1 ? pFileName.pop() : false;

                  let fileName = uuidv4();
                  if (fileExtension) fileName += `.${fileExtension}`;

                  const newFile = new File([file], fileName, {
                    type: file.type,
                  });

                  let staticURL = false;

                  try {
                    const staticURL_request = await self.$api.put(
                      `/fragments/${self.projectId}/static`,
                      {
                        objectName: newFile.name, // newFileName,
                      }
                    );
                    staticURL = staticURL_request?.data?.result?.url || false;
                  } catch (error) {
                    return { success: 0 };
                  }

                  if (!staticURL) return { success: 0 };

                  const resultFileName = URL.parse(staticURL)
                    .pathname.split("/")
                    .pop();

                  return self.$api
                    .put(staticURL, newFile)
                    .then(() => {
                      const finalURL = `${self.API_BASE_URL}/fragments/${self.projectId}/static/${resultFileName}/redirect`;
                      const ctx = {
                        success: 1,
                        file: {
                          url: finalURL,
                          size: file.size,
                          extension: fileExtension,
                          title: file.name,
                        },
                      };
                      return ctx;
                    })
                    .catch((error) => {
                      error;
                    });
                },
              },
            },
          },
          image: {
            class: ImageTool,
            config: {
              /**
               * Custom uploader
               */
              uploader: {
                /**
                 * Upload file to the server and return an uploaded image data
                 * @param {File} file - file selected from the device or pasted by drag-n-drop
                 * @return {Promise.<{success, file: {url}}>}
                 */
                // eslint-disable-next-line no-unused-vars
                async uploadByFile(file) {
                  const pFileName = file.name.split(".");
                  const fileExtension =
                    pFileName.length > 1 ? pFileName.pop() : false;

                  let fileName = uuidv4();
                  if (fileExtension) fileName += `.${fileExtension}`;

                  const newFile = new File([file], fileName, {
                    type: file.type,
                  });

                  let staticURL = false;

                  try {
                    const staticURL_request = await self.$api.put(
                      `/fragments/${self.projectId}/static`,
                      {
                        objectName: newFile.name, // newFileName,
                      }
                    );
                    staticURL = staticURL_request?.data?.result?.url || false;
                  } catch (error) {
                    return { success: 0 };
                  }

                  if (!staticURL) return { success: 0 };

                  const resultFileName = URL.parse(staticURL)
                    .pathname.split("/")
                    .pop();

                  return self.$api
                    .put(staticURL, newFile)
                    .then(() => {
                      const finalURL = `${self.API_BASE_URL}/fragments/${self.projectId}/static/${resultFileName}/redirect`;
                      const ctx = {
                        success: 1,
                        file: {
                          url: finalURL,
                          size: file.size,
                          extension: fileExtension,
                          title: file.name,
                        },
                      };
                      return ctx;
                    })
                    .catch((error) => {
                      error;
                    });
                },
                // eslint-disable-next-line no-unused-vars
                /**
                uploadByUrl(blobUrl) {
                  return self.$api.get("/users").then(async () => {
                    return {
                      success: 1,
                      file: {
                        url: blobUrl,
                        // any other image data you want to store, such as width, height, color, extension, etc
                      },
                    };
                  });
                }, **/
              },
            },
          },
        },
        onChange: function () {
          clearTimeout(self.onChange);
          self.onChange = setTimeout(self.save, 50);
        },
      });
    },
  },
};
</script>
<style>
.image-tool__image-picture {
  max-height: 350px;
}
</style>
