<template>
  <div>
    <editor-content id="editorjs" :editor="editor" />
  </div>
</template>

<script>
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import { v4 as uuidv4 } from "uuid";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";

const CustomDocument = Document.extend({
  content: "heading block*",
});

export default {
  name: "CardEditorTipTap",
  components: {
    EditorContent,
  },
  props: {
    card: Object,
    projectId: String,
    project: Object,
  },
  prev: {},
  data: () => ({
    editor: null,
    onChange: null,
  }),
  beforeDestroy() {
    self.editor?.destroy && self.editor.destroy();
  },
  mounted() {
    this.$nextTick(this.placeEditor);
  },
  methods: {
    save() {
      const self = this;
      self.card.content = self.editor.getJSON();
    },
    placeEditor() {
      const self = this;
      this.editor = new Editor({
        content: self.card.content,
        extensions: [
          CustomDocument,
          StarterKit.configure({
            document: false,
          }),
        ],
        onUpdate: () => {
          this.save();
        },
      });
    },
  },
};
</script>
<style>
.image-tool__image-picture {
  max-height: 350px;
}

.tiptap {
  outline: none;
}
</style>
