<template>
  <v-menu offset-x close-on-content-click>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :color="color"
        dark
        v-bind="attrs"
        v-on="on"
        :small="small === true"
        :class="btnClass"
      >
        {{ name || "OK" }}
      </v-btn>
    </template>
    <v-list class="pa-8">
      <v-btn
        width="100%"
        :small="small === true"
        class="mb-4"
        dark
        :color="colorConfirm || 'green'"
        @click="$emit('confirm')"
        >{{ nameConfirm.toUpperCase() }}</v-btn
      >
      <v-btn
        width="100%"
        :small="small === true"
        dark
        :color="colorDecline || 'red'"
        @click="$emit('decline')"
        >{{ nameDecline.toUpperCase() }}</v-btn
      >
    </v-list>
    <!--
    <v-list>
      <v-list-item
        link
        dense
        :style="{ 'background-color': color + ' !important;' }"
      >
        <v-list-item-title @click="$emit('ok')"
          ><b>{{ nameConfirm.toUpperCase() }}</b></v-list-item-title
        >
      </v-list-item>
      <v-list-item link dense>
        <v-list-item-title @click="$emit('decline')">{{
          nameDecline.toUpperCase()
        }}</v-list-item-title>
      </v-list-item>
    </v-list> -->
  </v-menu>
</template>

<script>
export default {
  name: "ConfirmationButton",
  props: {
    name: String,
    color: String,
    small: Boolean,
    btnClass: String,
    "name-confirm": String,
    "name-decline": String,
    "color-confirm": String,
    "color-decline": String,
  },
};
</script>
